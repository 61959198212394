<template>
  <div class="main">
    <HeaderLogin/>
    <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import HeaderLogin from "@/components/HeaderLogin.vue";

export default {
  name: "LoginView",
  components: {HeaderLogin, Footer}
}
</script>

<style scoped>
.main {
  min-width: 1140px;
  margin: 0 auto;
}
</style>
